<script setup lang="ts">
import { Icon, Button, TextField } from '@/components/common'
import { computed, ref } from 'vue'
import Icons from "@/assets/icons";

const ColorsMenu = [
  '#1744ff',
  '#693edd',
  '#1e1f24',
  '#e5484d',
  '#ffc53d',
  '#46a758',
]

interface IconSelectorData {
  color?: string,
  icon?: keyof typeof Icons;
}

const props = defineProps<IconSelectorData>()

const emits = defineEmits<{
  (e: 'update', data: IconSelectorData): void
}>()

const search = ref<string>('')
const color = ref<string | undefined>(props.color)
const icon = ref<keyof typeof Icons | undefined>(props.icon)

const handleIcon = (value: keyof typeof Icons) => {
  icon.value = value
  emits('update', { icon: value })
}

const handleColor = (value: string) => {
  color.value = value
  emits('update', { color : value})
}

const icons = computed(() => {
  const query = search.value.toLowerCase()
  return Object.keys(Icons).filter((key) => {
    return key !== 'vanillaLogo' && key.toLowerCase().includes(query)
  }) as Array<keyof typeof Icons>
})

</script>
<template>
  <div :class="$style.container">
    <p>
      Select icon
    </p>
    <div :class="$style['colors-list']">
      <div
        v-for="colorOption in ColorsMenu"
        :key="colorOption"
        :style="{ backgroundColor: `${colorOption}` }"
        :class="[$style.color, color === colorOption && $style.active]"
        @click="() => handleColor(colorOption)"
      />
    </div>
    <TextField v-model="search" placeholder="Search icons">
      <template #pre>
        <Icon icon="magnifyingGlass" :size="18" />
      </template>
    </TextField>
    <div :class="$style['icons-list']">
      <Button
        v-for="icon in icons"
        :key="icon"
        :class="[icon === icon && $style['active-icon']]"
        size="2"
        variant="ghost"
        theme="neutral"
        @click="() => handleIcon(icon)"
      >
        <template #icon>
          <Icon :icon="icon" :size="20" />
        </template>
      </Button>
    </div>
  </div>
</template>
<style module lang="scss">
.container {
  width: 324px;
  padding: 16px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p {
    @extend .regular-3;

    span {
      @extend .semibold-3;
    }
  }

  .colors-list {
    display: flex;
    justify-content: flex-start;
    column-gap: 12px;

    .color {
      width: 22px;
      height: 22px;
      border-radius: 8px;
      border: 2px solid var(--background-1);
      padding: 2px;

      &:hover {
        box-shadow: 0px 0px 0px 2px var(--neutral-alpha-4);
      }

      &.active {
        box-shadow: 0px 0px 0px 2px var(--neutral-alpha-12);
      }
    }
  }

  .icons-list {
    display: flex;
    column-gap: 3px;
    row-gap: 4px;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 150px;
    overflow: auto;

    .active-icon {
      background-color: var(--neutral-alpha-5);
    }
  }
}
</style>
