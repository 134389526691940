<script setup lang="ts">
import { AutoComplete, Avatar, Button } from '@/components/common'
import User from '@/models/user'
import { useWorkspaceStore } from '@/store/workspace'
import { toRefs } from 'vue'

const props = defineProps<{
  members: User[],
  emptyText: string
}>()

const { members, emptyText } = toRefs(props)

const emit = defineEmits<{
  (e: 'add', user: User): void
  (e: 'remove', user: User): void
}>()

const store = useWorkspaceStore()

const handleAdd = (user: User) => {
  const isExisting = !!members.value.find((member) => member.id === user.id)

  if (!isExisting) {
    emit('add', user)
  }
}

const handleDelete = (index: number) => {
  emit('remove', members.value[index])
}

</script>
<template>
  <div :class="['popup-content', $style.container]">
    <AutoComplete
      size="2"
      :items="store.members"
      :clear-on-select="true"
      item-key="id"
      title-key="name"
      subtitle-key="email"
      placeholder="Invite others by name or email"
      @select="handleAdd"
    >
      <template #icon="{ item }">
        <Avatar :size="32" :title="item.name" :src="item.avatar" />
      </template>
    </AutoComplete>
    <h2 :class="$style.heading">Who has access</h2>
    <ul v-if="members.length" :class="$style.list">
      <li v-for="(member, index) in members" :key="member.id">
        <Avatar :size="32" :title="member.name" :src="member.avatar" />
        <div :class="$style.details">
          <b>{{ member.name }}</b>
          <p>{{ member.email }}</p>
        </div>
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          icon="crossSmall"
          @click="() => handleDelete(index)"
        />
      </li>
    </ul>
    <p v-else :class="$style.empty">
      {{ emptyText }}
    </p>
  </div>
</template>
<style module lang="scss">
.container {
  width: 420px;
}
.heading {
  @extend .medium-2;
  margin: 24px 0px;
}
.empty {
  @extend .regular-3;
  color: var(--neutral-11);
}
.list {
  margin-bottom: 32px;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;

    &:not(:first-child) {
      padding-top: 12px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--neutral-alpha-4);
      padding-bottom: 12px;
    }

    .details {
      flex-grow: 1;
      row-gap: 2px;

      b {
        @extend .medium-3;
      }
      p {
        @extend .regular-2;
        color: var(--neutral-11);
      }
    }
  }
}
</style>
