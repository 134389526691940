<script setup lang="ts">
import { Button, Checkbox, Confirm } from '@/components/common'
import { computed, ref, toRefs } from 'vue'
import { showToast } from '@/utils'
import {useCollabStore} from '../../store'
import MoveToFolder from '../MoveToFolder.vue'
import Popup from '@/components/common/Popup.vue';
const store = useCollabStore()
import { useRouter } from 'vue-router'

const router = useRouter()

const confirmDeletePopup = ref<typeof Confirm>()
const moveToFolderPopup = ref<InstanceType<typeof Popup> | null>(null)

const props = defineProps<{
  selectedIds: number[]
  total: number
}>()

const { selectedIds, total } = toRefs(props)

const emit = defineEmits<{
  'check-all': [value: boolean]
}>()

const amount = computed(() => selectedIds.value.length)

const isIntermediate = computed(() => {
  return !!amount.value && amount.value !== total.value
})

const handleCheckbox = () => {
  if (amount.value === total.value) {
    emit('check-all', false)
    return
  }

  emit('check-all', true)
}

const handleDelete = async () => {
  store.deleteAssetsInBulk(selectedIds.value).then(() => {
    emit('check-all', false)
    showToast({
      type: 'success',
      text: `(${amount.value}) files were deleted`,
    })
  })
}

const handleDeleteConfirm = () => {
  confirmDeletePopup.value?.show({
    title: 'Delete files',
    message: `You're about to delete ${amount.value} files. This action is permanent and cannot be undone. You and your team members will lose access to those files forever.`,
    actions: [
      {
        title: `Delete (${amount.value}) files`,
        theme: 'danger',
        handler: handleDelete,
      },
    ],
  })
}

const handleMoveFolder = (parentId: number) => {
    store.moveAssetsInBulk(selectedIds.value, parentId)
      .then(() => {
        const {params} = router.currentRoute.value;

        return store.loadAssets({
            'include': 'members,subAssetsCount',
            ...(params.folderId ? {'filter[parent_id]': Number(params.folderId)} : {'filter[no_parent]': true})
          })
      }).then(() => {
        moveToFolderPopup.value?.hide()
      })
}

</script>

<template>
  <tr :class="$style.row">
    <th>
      <Checkbox
        size="1"
        :intermediate="isIntermediate"
        :model-value="amount === total"
        @click.prevent="handleCheckbox"
      />
    </th>

    <th colspan="6">
      <div :class="$style.content">
        <div :class="$style.count">{{ amount }} selected</div>

        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          :class="$style.link"
          icon="trashCan2"
          @click.prevent="() =>moveToFolderPopup?.show()"
        >
          Move
        </Button>

        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          :class="$style.link"
          icon="trashCan2"
          @click.prevent="handleDeleteConfirm"
        >
          Delete
        </Button>
      </div>
    </th>
  </tr>

  <Confirm ref="confirmDeletePopup" />
    <Popup ref="moveToFolderPopup" title="Move Folder">
      <MoveToFolder  @submit="handleMoveFolder"  @cancel="moveToFolderPopup?.hide()"/>
    </Popup>
</template>

<style module lang="scss">
.row {
  background-color: var(--neutral-alpha-3);
  border-bottom: 1px solid var(--neutral-alpha-4);

  th {
    padding: 10px 0;
    vertical-align: middle;

    &:first-of-type {
      border-top-left-radius: 12px;
      padding-left: 20px;
      padding-right: 16px;
    }

    &:last-of-type {
      border-top-right-radius: 12px;
      padding-right: 20px;
      vertical-align: middle;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 16px;

    .count {
      @extend .medium-3;

      flex: 1;
      text-align: left;
    }
  }

  .link {
    padding: 0;
    color: var(--neutral-alpha-11);

    &:hover {
      color: var(--neutral-alpha-12);
      background-color: transparent;
    }
  }
}
</style>
