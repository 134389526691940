<script setup lang="ts">
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Breadcrumbs, type Crumb, Page } from '@/components/common/layout'
import { useWorkspaceStore } from '@/store/workspace'
import {
  Button,
  Confirm,
  EmptyView,
  RichEditor,
  Skeleton,
} from '@/components/common'
import Composer from './components/Composer.vue'
import { useTasksTeamStore } from './teamStore'
import TaskDetails from './components/Tasks/TaskDetails.vue'
import { TaskUpdateRequest } from './api/types'
import { watchDebounced } from '@vueuse/core'
import TaskLog from '@modules/tasks/components/Log/TaskLog.vue'
import { TaskComment } from '@modules/tasks/models/taskComment.ts'
import AttachmentUploader from '@modules/tasks/components/Attachment/AttachmentUploader.vue'
import SubTasks from '@modules/tasks/components/SubTasks/SubTasks.vue'
import StageIndicator from '@modules/tasks/components/StageIndicator.vue'
import { Task } from '@modules/tasks/models/task.ts'
import { showToast } from '@/utils'
import Echo from 'laravel-echo'
import { TaskLog as TaskLogModel } from '@modules/tasks/models/taskLog.ts'
import { Pagination } from '@/utils/api/types.ts'
import { normalizeText } from '@/utils/tasks'
import { orderBy } from 'lodash'

const socket = inject<Echo>('socket')
const router = useRouter()
const taskNumber = ref(Number(router.currentRoute.value.params.taskNumber))
const teamId = ref(Number(router.currentRoute.value.params.teamId))
const logs = ref<Pagination<TaskLogModel>>({
  data: [],
  links: {
    next: '',
  },
  meta: {
    current_page: 0,
    per_page: 0,
    total: 0,
  },
})

const form = reactive<TaskUpdateRequest>({
  attachments: {
    files: [],
    images: [],
    removed_files: [],
    removed_images: [],
  },
})

onMounted(() => {
  socket &&
    socket
      .private(
        `projectManagement.teams.${teamId.value}.tasks.${taskNumber.value}.logs`,
      )
      .listen('.LogCreated', (log: TaskLogModel) => {
        logs.value.data.push(log)
      })
      .listen('.LogDeleted', (log: Partial<TaskLogModel>) => {
        logs.value.data = logs.value.data.filter((item) => item.id !== log.id)
      })
      .listen('.LogUpdated', (log: TaskLogModel) => {
        logs.value.data = orderBy(
          logs.value.data.map((item) => (item.id === log.id ? log : item)),
          ['updatedAt'],
          ['asc'],
        )
      })
})

const replyComment = ref<TaskComment>()
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const logsLoaded = ref<boolean>(false)

const workspaceStore = useWorkspaceStore()
const { workspace, api } = workspaceStore

const store = useTasksTeamStore()

store.loadTask(teamId.value, taskNumber.value).then(() => {
  if (!store.task) return

  document.title = `${store.task?.team?.code}-${store.task?.number} ${store.task?.title} | Task`

  form.title = store.task.title || ''
  form.description = store.task.description || ''
  form.attachments = {
    ...store.task?.attachments,
    removed_files: [],
    removed_images: [],
  }
  getTaskLogs()

  if (!store.team || store.team?.id !== store.task.teamId)
    store.loadTeam(store.task.teamId)
})

watch(
  () => router.currentRoute.value.params.taskNumber,
  () => {
    taskNumber.value = Number(router.currentRoute.value.params.taskNumber)
    teamId.value = Number(router.currentRoute.value.params.teamId)

    store.loadTask(teamId.value, taskNumber.value).then(() => {
      if (!store.task) return
      form.title = store.task.title || ''
      form.description = store.task.description || ''
      form.attachments = {
        ...store.task?.attachments,
        removed_files: [],
        removed_images: [],
      }

      if (!store.team || store.team?.id !== store.task.teamId)
        store.loadTeam(store.task.teamId)
    })
  },
)
const getTaskLogs = async () => {
  logs.value = await api.task.getTaskLogs(store.task?.id as number)
  logsLoaded.value = true
}

const handleComment = () => {
  replyComment.value = undefined
}

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Tasks`,
    url: { name: 'Tasks' },
  },
  {
    title: 'Teams',
    url: { name: 'TasksTeams' },
  },
  {
    title: store.team?.name || '',
    url: { name: 'TasksTeam', params: { teamId: store.team?.id || 0 } },
  },
  {
    title: `${store.team?.code}-${store.task?.number}`,
  },
])

const handleReply = (parentComment: TaskComment) => {
  replyComment.value = parentComment
  window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: 'smooth',
  })
}

const handleCancelReply = () => {
  replyComment.value = undefined
}

const handleTaskDelete = () => {
  store.task &&
    confirmPopup.value &&
    confirmPopup.value.show({
      title: 'Delete task',
      message: `You are about to delete this task. Are you sure? All files and messages will be lost.`,
      actions: [
        {
          title: 'Cancel',
          theme: 'neutral',
          variant: 'ghost',
        },
        {
          title: `Delete task`,
          theme: 'danger',
          handler: () => {
            store.deleteTask(store.task?.id as number)

            router.replace({
              name: 'TasksTeam',
              params: { teamId: store.team?.id || 0 },
            })
          },
        },
      ],
    })
}

const handleTaskUpdate = () => {
  if (
    store.task?.title === form.title &&
    normalizeText(store.task?.description || '') ===
      normalizeText(form.description || '') &&
    form.attachments?.files.filter((f) => f instanceof File).length === 0 &&
    form.attachments?.images.filter((i) => i instanceof File).length === 0 &&
    form.attachments?.removed_images.length === 0 &&
    form.attachments?.removed_files.length === 0
  ) {
    return
  }

  store.task &&
    api.task.updateTask(form, store.task.id).then((task) => {
      store.task = task

      form.attachments = {
        ...task.attachments,
        removed_files: [],
        removed_images: [],
      }

      store.tasks = store.tasks.map((item) =>
        item.id === task.id ? { ...item, ...task } : item,
      )
    })
}

watchDebounced(form, handleTaskUpdate, { debounce: 500, deep: true })
watch(
  () => store?.task?.id,
  () => {
    getTaskLogs()
  },
)
const onRemovedImage = (id: number) => {
  form.attachments.removed_images = [id]
}

const onRemovedFile = (id: number) => {
  form.attachments.removed_files = [id]
}

const onSubTaskCreated = (subTask: Task) => {
  store.task?.subTasks?.push(subTask)
}

const handleCopyLink = () => {
  navigator.clipboard.writeText(window.location.href).then(() => {
    showToast({ text: 'Task URL copied to clipboard', type: 'success' })
  })
}
const handleBackToTasks = () => {
  router.push({ name: 'TasksTeam', params: { teamId: teamId.value } })
}
</script>
<template>
  <Page :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
    </template>
    <EmptyView
      v-if="!store.task && !!store.error"
      :class="$style.empty"
      icon="arCube1"
      title="Task not found"
      description="Oops! The task you're looking for doesn't exist. It might have been deleted or never existed in the first place."
    >
      <Button
        size="2"
        variant="outline"
        theme="neutral"
        @click="handleBackToTasks"
        >Back to tasks</Button
      >
    </EmptyView>
    <template v-else>
      <div :class="$style.content">
        <div :class="$style['title-area']">
          <input
            v-model="form.title"
            :class="$style.title"
            placeholder="Task title"
          />

          <div v-if="store.task?.parent" :class="$style.parent">
            <p :class="$style.parentLabel">Sub task of</p>
            <StageIndicator
              :size="16"
              :stage="store.task.parent?.status?.order ?? 0"
              :total-stages="store.task.parent?.team?.statuses?.length ?? 1"
            />
            <router-link
              :class="$style.parentTitle"
              :to="{
                name: 'TasksTask',
                params: { taskNumber: store.task?.parent?.number },
              }"
            >
              {{ store.task?.parent?.title }}
            </router-link>
          </div>

          <RichEditor
            v-if="form.description !== undefined"
            v-model="form.description"
            :class="$style.details"
            placeholder="Task description..."
          />

          <AttachmentUploader
            v-model:files="form.attachments.files"
            v-model:images="form.attachments.images"
            @removed-file="onRemovedFile"
            @removed-image="onRemovedImage"
          />
        </div>

        <SubTasks
          v-if="store.task"
          :task="store.task as Task"
          @created="onSubTaskCreated"
        />

        <hr :class="$style.separator" />
        <b :class="$style.heading"> Activity </b>

        <div v-if="store.isStatus('loading') && !logsLoaded">
          <Skeleton v-for="n in 5" :key="n" :height="20" />
        </div>
        <template v-if="logsLoaded">
          <TaskLog
            v-for="log in logs.data"
            :key="log.id"
            :task-log="log"
            @comment-reply="(data) => handleReply(data)"
          />
        </template>

        <Composer
          v-if="store.task"
          :task-id="store.task?.id"
          :parent-comment="replyComment"
          @send="handleComment"
          @cancel-reply="handleCancelReply"
        />
      </div>
    </template>
    <template #detailsTopBar>
      Details
      <div :class="$style['cta']">
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          icon="trashCan2Outline"
          @click="handleTaskDelete"
        />
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          icon="chainLink"
          @click="handleCopyLink"
        />
      </div>
    </template>
    <template #details>
      <TaskDetails v-if="store.task" />
    </template>
    <Confirm ref="confirmPopup" />
  </Page>
</template>
<style module lang="scss">
.empty {
  margin-top: 64px;
}
.content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 32px;

  .title-area {
    margin-bottom: 8px;

    .title {
      @extend .semibold-4;
      font-family: inherit;
      width: 100%;
      border: 0px;
      background-color: transparent;
      outline: none;
      padding: 0px;
      margin: 24px 0 12px;

      &::placeholder {
        color: var(--neutral-alpha-9);
      }
    }

    .parent {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .parentLabel {
        @extend .regular-3;
        color: var(--neutral-11);
        margin-right: 8px;
      }

      .parentTitle {
        @extend .medium-3;
        color: var(--neutral-alpha-12);
        margin-left: 8px;
      }
    }

    .details {
      min-height: 30px;
      overflow: auto;
    }
  }

  .separator {
    border: 1px solid var(--neutral-alpha-4);
    margin-bottom: 8px;
  }

  .heading {
    @extend .semibold-4;
  }
}

.cta {
  display: flex;
}
</style>
