<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { noop } from 'lodash'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import { Alert, Button, Icon, Label, TextField } from '@/components/common'
import Checkbox from '@/components/common/Checkbox.vue'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import useApi from '@/composables/api'
import { email, minLength, required } from '@vuelidate/validators'

const googleAuth = `${import.meta.env.VITE_API_HOST}social/connect/google`

const router = useRouter()
const { api, isStatus, error } = useApi()

const isPassVisible = ref(false)

const form = reactive({
  email: '',
  password: '',
  remember: false,
})

const formRules = {
  email: { required, email },
  password: {
    required,
    min: minLength(6),
  },
  remember: {},
}

const form$ = useVuelidate(formRules, form)

const handleSubmit = () => {
  const { initial } = router.currentRoute.value.query ?? {}
  api.auth
    .login(form)
    .then(() =>
      initial
        ? router.push(initial as string)
        : router.push({ name: 'Splash' }),
    )
    .catch(noop)
}
</script>

<template>
  <AuthLayout title="Login to your Vanilla account">
    <form @submit.prevent="handleSubmit">
      <Label :class="$style.label">
        Email
        <TextField
          v-model="form$.email.$model"
          type="email"
          size="2"
          placeholder="johndoe@gmail.com"
          :error="error"
        />
      </Label>

      <Label :class="$style.label">
        Password
        <div :class="$style.password_wrapper">
          <TextField
            v-model="form$.password.$model"
            placeholder="*********"
            size="2"
            :type="isPassVisible ? 'text' : 'password'"
            :error="error"
          />

          <Button
            type="button"
            variant="ghost"
            theme="neutral"
            size="1"
            :icon="isPassVisible ? 'eyeClosed' : 'eyeOpen'"
            @click.prevent="isPassVisible = !isPassVisible"
          />
        </div>
      </Label>

      <div :class="[$style.underlabel, $style.actionsRow]">
        <Checkbox v-model="form$.remember.$model" size="2">
          <div :class="$style.checkboxLabel">Remember me</div>
        </Checkbox>

        <Button
          type="button"
          size="3"
          theme="neutral"
          variant="alternative"
          as="router-link"
          :class="$style.link"
          :to="{ name: 'forgot' }"
        >
          Forgot Password?
        </Button>
      </div>

      <div :class="$style.actions">
        <Button
          type="submit"
          variant="solid"
          theme="neutral"
          size="3"
          :disabled="form$.$invalid || isStatus('posting')"
          :loading="isStatus('posting')"
        >
          Continue with email
        </Button>

        <Button
          as="a"
          icon="google"
          theme="neutral"
          variant="outline"
          size="3"
          type="button"
          :href="googleAuth"
          :class="$style.secondary"
        >
          Continue with Google
        </Button>
      </div>
    </form>

    <div :class="$style.footerActions">
      <div>New to Vanilla?</div>

      <router-link :to="{ name: 'signup' }" :class="$style.link">
        Create an account
      </router-link>
    </div>

    <Alert v-if="error" :error="error" :class="$style.alert">
      <template #icon>
        <Icon icon="circleExclamation" :size="20" />
      </template>
    </Alert>
  </AuthLayout>
</template>

<style lang="scss" module>
@import './styles.scss';
</style>
