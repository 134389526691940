import { acceptHMRUpdate, defineStore } from 'pinia'
import { useWorkspaceApi } from '@/composables/api'
import type Shortcut from './models/shortcut'
import type Ticket from './models/ticket'
import type User from '@/models/user'
import type Api from '@/utils/api'
import type { Pagination } from '@/utils/api/types'
import { tabs } from './constants'
import type { TicketInterval, TicketStatus } from './models/ticket'
import type { DNS } from '@/models/DNS'
import { useWorkspaceStore } from '@/store/workspace'
import { tracker } from '@/utils'
import { brevo } from '@/utils/brevo'
import { Menu } from '@/pages/Dashboard/constants'

interface InboxState extends ReturnType<typeof useWorkspaceApi> {
  pagination?: Pagination<Ticket>
  tickets: Array<Ticket>
  shortcuts: Array<Shortcut>
  domain: { domain: string; dns: DNS[]; isVerified: boolean } | null
  unreadMessagesCount: number
  api: Api
}

export interface Search {
  type: number
  status: TicketStatus[]
  assignee: number[]
  timeInterval: TicketInterval
  search?: string
}

export const useInboxStore = defineStore('inbox', {
  state: (): InboxState => ({
    tickets: [],
    shortcuts: [],
    domain: null,
    unreadMessagesCount: 0,
    ...useWorkspaceApi(),
  }),
  getters: {
    forwardEmail() {
      const store = useWorkspaceStore()
      return `${store.workspace?.publicId}@${import.meta.env.VITE_APP_INBOX_MAIL_DOMAIN}`
    },
    menuItems(): Menu {
      if ((this.pagination?.totalItems || 0) === 0) {
        return {
          badge: this.unreadMessagesCount,
          children: [
            {
              icon: 'settingsGear1',
              title: 'Setup',
              url: { name: 'InboxSetup' },
            },
          ],
        }
      }
      return {
        badge: this.unreadMessagesCount,
        children: [
          {
            icon: 'bubbles',
            title: 'All messages',
            url: { name: 'HDInbox' },
          },
          {
            icon: 'pageAdd',
            title: 'Live Chat Widget',
            url: { name: 'HDWidget' },
          },
          {
            icon: 'settingsGear2',
            title: 'Settings',
            url: { name: 'InboxSettings' },
          },
        ],
      }
    },
  },
  actions: {
    loadTickets(searchState: Search) {
      const query = []
      if (searchState.type) {
        query.push(`type=${tabs[searchState.type].key}`)
      }
      if (searchState.status.length > 0) {
        const statuses = searchState.status.map((status) => status)
        query.push(`status=${statuses.join(',')}`)
      }
      if (searchState.assignee.length > 0) {
        query.push(`assignee=${searchState.assignee.join(',')}`)
      }
      if (searchState.timeInterval) {
        query.push(`interval=${searchState.timeInterval}`)
      }
      if (searchState.search) {
        query.push(`search=${searchState.search}`)
      }
      return this.api.inbox.loadTickets(query).then((data) => {
        this.pagination = data
        this.tickets = data.data
      })
    },
    loadNextPage() {
      return (
        this.pagination?.links.next &&
        this.api
          .loadNextPage<Ticket>(this.pagination?.links.next)
          .then((data) => {
            this.pagination = data
            this.tickets = [...this.tickets, ...data.data]
          })
      )
    },
    updateAssignee(ticket: Ticket, users: User[]) {
      this.api.inbox.updateAssignee(ticket, users).then((data) => {
        const index = this.tickets.findIndex((t) => t.id === ticket.id)
        ticket.operators = data.operators
        ticket.status = data.status
        this.tickets.splice(index, 1, ticket)
      })
    },
    updateStatus(ticket: Ticket, status: TicketStatus) {
      return this.api.inbox.updateTicketStatus(ticket, status).then((data) => {
        const index = this.tickets.findIndex((t) => t.id === ticket.id)
        ticket.status = data.status
        this.tickets.splice(index, 1, ticket)
      })
    },
    addNewTicket(ticket: Ticket) {
      this.tickets = [ticket, ...this.tickets]
      this.pagination?.totalItems === 0 &&
        (this.pagination.totalItems = this.tickets.length)
    },
    addShortcut(payload: Shortcut) {
      return this.api.inbox.addShortcut(payload).then((shortcut) => {
        const s = [...this.shortcuts]
        s.push(shortcut)
        this.shortcuts = s
      })
    },
    editShortcut(payload: Shortcut) {
      const index = this.shortcuts.findIndex((s) => s.id === payload.id)
      this.shortcuts.splice(index, 1, { ...payload })
      return this.api.inbox.editShortcut(payload)
    },
    deleteShortcut(payload: Shortcut) {
      const index = this.shortcuts.indexOf(payload)
      const s = [...this.shortcuts]
      s.splice(index, 1)
      this.shortcuts = s
      return this.api.inbox.deleteShortcut(payload)
    },
    async getShortcuts() {
      this.shortcuts = await this.api.inbox.loadShortcuts()
    },
    addDomain(email: string) {
      return this.api.inbox.addDomain(email).then((data) => {
        this.domain = data

        tracker.trackEvent('started_custom_domain_setup', {
          domain: data.domain,
        })
        brevo.updateAttributes({
          STARTED_CUSTOM_DOMAIN_SETUP: true,
        })
      })
    },
    getDomain() {
      return this.api.inbox.getDomain().then((data) => {
        this.domain = data
      })
    },
    checkDomain() {
      return (
        this.domain &&
        this.api.inbox.checkDomain().then((data) => {
          this.domain && (this.domain.isVerified = data.status)
          return data
        })
      )
    },

    updateStatusBulk(ids: number[], status: TicketStatus) {
      return this.api.inbox.updateTicketsStatusBulk(ids, status).then(() => {
        this.tickets = this.tickets.map((t) =>
          ids.includes(t.id) ? { ...t, status } : t,
        )
      })
    },

    async updateAssigneeBulk(ids: number[], to: number[]) {
      return this.api.inbox.updateTicketsAssigneeBulk(ids, to).then((data) => {
        this.tickets = this.tickets.map((t) =>
          ids.includes(t.id) ? { ...t, operators: data.operators } : t,
        )
      })
    },

  async deleteTicketsBulk(ids: number[]) {
    return this.api.inbox.deleteTicketsBulk(ids).then(() => {
      this.tickets = this.tickets.filter((t) => !ids.includes(t.id))
    })
  },

    async getUnreadMessagesCount() {
      return this.api.inbox
        .getUnreadMessagesCount()
        .then((response) => (this.unreadMessagesCount = response))
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInboxStore, import.meta.hot))
}
