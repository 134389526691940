import { useWorkspaceApi } from '@/composables/api'
import { defineStore } from 'pinia'
import type Api from '@/utils/api'
import { Menu } from '@/pages/Dashboard/constants'
import { Asset } from './models/asset'
import User from '@/models/user'
import { LoadAssetsParams } from './api/types'

interface CollabStore extends ReturnType<typeof useWorkspaceApi> {
  assets: Asset[],
  folder: Asset | null,
  favoriteAssets: Asset[],
  api: Api,
  selectedIds: number[]
}

export const useCollabStore = defineStore('collab', {
  state: (): CollabStore => ({
    assets: [],
    folder: null,
    selectedIds: [],
    favoriteAssets: [],
    ...useWorkspaceApi(),
  }),
  getters: {
    menuItems(): Menu {
      return {
        children: [
          {
            icon: 'house',
            title: 'Home',
            url: { name: 'CollabHome' },
          },
          ...this.favoriteAssets.map((asset) => ({
            icon: asset.type === 'file' ? 'fileBend' : asset.icon || 'folder2',
            title: asset.title,
            url: { name: 'CollabFolder', params: { folderId: asset.id } },
          })),
        ],
      }
    },
  },
  actions: {
    toggleId(id: number): void {
      if (this.selectedIds.includes(id)) {
        this.selectedIds = [...this.selectedIds.filter(item => item !== id)]
        return
      }

      this.selectedIds = [...this.selectedIds, id]
    },
    createAsset(data: Partial<Asset>) {
      return this.api.collab.createAsset(data).then((response) => {
        this.assets.push(response)
      })
    },

    loadAsset(assetId: number) {
      return this.api.collab.loadAsset(assetId).then((data) => {
        this.folder = data
      })
    },

    loadAssets(params?: LoadAssetsParams) {
      return this.api.collab.loadAssets(params ?? {}).then((data) => {
        this.assets = data
      })
    },

    loadFavoriteAssets() {
      return this.api.collab.loadAssets({'filter[favorites]': true})
        .then((data) => {
          this.favoriteAssets = data
        })
    },

    updateAsset(id: number, payload: Partial<Asset>) {
      const index = this.assets.findIndex((p) => p.id === id)

      if (index !== -1) {
        const updatedAsset = {
          ...this.assets[index],
          ...payload,
        }

        this.assets[index] = updatedAsset
      }

      return this.api.collab.updateAsset(id, payload)
    },

    addAssetMember(id: number, member: User) {
      const index = this.assets.findIndex((p) => p.id === id)

      if (index !== -1) {
        const asset = this.assets[index];

        this.assets[index] = {
          ...asset,
          members: [...(asset.members ?? []), member],
          membersCount: (asset.membersCount ?? 0) + 1
        }
      }

      return this.api.collab.createAssetMember(id, member.id)
    },

    removeAssetMember(id: number, member: User) {
      const index = this.assets.findIndex((p) => p.id === id)

      if (index !== -1) {
        const asset = this.assets[index]

        this.assets[index] = {
          ...asset,
          members: (asset.members ?? []).filter(item => item.id !== member.id),
          membersCount: (asset.membersCount ?? 1) - 1,
        }
      }

      return this.api.collab.deleteAssetMember(id, member.id)
    },

    deleteAsset(id: number) {
      this.assets = [...this.assets].filter(asset => asset.id !== id)
      this.favoriteAssets = [...this.favoriteAssets].filter(asset => asset.id !== id)

      return this.api.collab.deleteAsset(id)
    },

    toggleFavorite(asset: Asset, isFavorite: boolean) {
      const index = this.assets.findIndex((p) => p.id === asset.id)

      if (index !== -1) {
        const updatedAsset = {
          ...this.assets[index],
          isFavorite: isFavorite
        }

        this.assets[index] = updatedAsset
      }

      this.favoriteAssets = isFavorite ?
        [...this.favoriteAssets, asset] :
        this.favoriteAssets.filter(favoriteAsset => favoriteAsset.id !== asset.id )

      return isFavorite ?
        this.api.collab.favorite(asset.id) :
        this.api.collab.unFavorite(asset.id)
    },

    async deleteAssetsInBulk(ids: number[]) {
      return this.api.collab.deleteAssets(ids).then(() => {
        this.assets = this.assets.filter((t) => !ids.includes(t.id))
        this.favoriteAssets = this.favoriteAssets.filter((t) => !ids.includes(t.id))
        this.selectedIds = []
      })
    },

    async moveAssetsInBulk(ids: number[], parentId: number | null) {
      return this.api.collab.updateAssets({
        items: ids.filter(id => id !== parentId),
        parent_id: parentId
      }).then(() => {
        this.selectedIds = []
      })
    },
  },
})
