<script setup lang="ts">
import { computed, toRefs } from 'vue'

const props = defineProps<{
  src?: string
  size: number
  title?: string
  radius?:number
}>()
const { src, size, title } = toRefs(props)

const initials = computed(() => {
  if (!title?.value) return ''
  const parts = title.value.split(' ')
  if (parts.length > 1) {
    return `${parts[0][0]}${parts[1][0]}`
  }
  return `${parts[0][0]}${parts[0][1]}`
})

const getHashOfTitle = () => {
  if (!title?.value) return 0
  let hash = 0
  for (let i = 0; i < title.value.length; i++) {
    hash = title.value.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min)
}

const generateTitleHSL = () => {
  const hash = getHashOfTitle()
  const h = normalizeHash(hash, 180, 360)
  const s = normalizeHash(hash, 80, 100)
  const l = normalizeHash(hash, 30, 50)
  return `hsl(${h}, ${s}%, ${l}%)`
}
const avatarRadius = computed(() => {
  return `${props.radius ?? 999}px`
})

const backgroundColor = computed(() => {
  // generate a random persistent color for avatar
  return generateTitleHSL()
})
</script>
<template>
  <span
    :class="[$style.container, (size || 16) <= 20 && $style.small]"
    :style="{
      ...(src ? { backgroundImage: `url(${src})` } : {}),
      backgroundColor,
      width: `${size || 16}px`,
      height: `${size || 16}px`,
    }"
  >
    <slot v-if="!src && initials">{{ initials }}</slot>
  </span>
</template>
<style module lang="scss">
.container {
  font-size: 0.8em;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: v-bind(avatarRadius);
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  cursor: pointer;
  color: var(--basic-white);
  text-transform: uppercase;
  user-select: none;
  box-shadow: 0px -4px 8px -2px var(--neutral-alpha-3) inset;

  &.small {
    font-size: 0.5em;
  }
}
</style>
