import {
  inputRules,
  wrappingInputRule,
  textblockTypeInputRule,
  smartQuotes,
  emDash,
  ellipsis,
  InputRule,
} from 'prosemirror-inputrules'
import { MarkType, NodeType, Schema } from 'prosemirror-model'
// custom types for getAttr
interface MarkInputRuleAttrs {
  type: 'email' | 'uri'
  href?: string
  target?: string
}

//This regex matches URLs with or without a protocol (http://, https://, or ftp://)
// and optional credentials (username:password@). It captures the main domain,
// which can include subdomains and top-level domains like .com or .org
// If the URL starts with www., it’s matched as part of the domain
// Optional elements include a port number (e.g., :8080) and a path (e.g., /path/to/resource).
// The URL must end with a space.
// The regex is case-insensitive and ensures valid formatting for common URL patterns,
// even if no protocol is specified (e.g., example.com or test.com).

const linkRegex =
  /(?:(?:(https|http|ftp)+):\/\/)?(?:\S+(?::\S*)?(@))?(?:(?:([a-z0-9][a-z0-9\\-]*)?[a-z0-9]+)(?:\.(?:[a-z0-9\\-])*[a-z0-9]+)*(?:\.(?:[a-z]{2,})(:\d{1,5})?))(?:\/[^\s]*)?\s$/i

// defines custom rule to detect links and emails, and create marks for them
function LinkInputRule(
  regexp: RegExp,
  markType: MarkType,
  getAttrs: (match: RegExpMatchArray) => MarkInputRuleAttrs,
): InputRule {
  return new InputRule(regexp, (state, match, start, end) => {
    const $start = state.doc.resolve(start)
    const attrs = getAttrs(match)
    if (!$start.parent.type.allowsMarkType(markType)) return null
    const oLinkString = match[0].substring(0, match[0].length - 1)
    const href = oLinkString.startsWith('www.')
      ? `https://${oLinkString}` // Prefix https:// to www. URLs
      : oLinkString.startsWith('http://') || oLinkString.startsWith('https://')
        ? oLinkString // Use the URL as-is if it starts with http/https
        : `https://${oLinkString}` // Prefix https:// to everything else

    const oAttr =
      attrs.type == 'email'
        ? { href: 'mailto:' + oLinkString }
        : { href: href, target: '_blank' }
    const oLink = markType.create(oAttr)
    const oPos = { from: start, to: end }
    const tr = state.tr
      .removeMark(oPos.from, oPos.to, markType)
      .addMark(oPos.from, oPos.to, oLink)
      .insertText(match[5], oPos.to)
      .insertText(' ')
    return tr
  })
}

// : (MarkType) → InputRule
// Given a link mark type, returns an input rule that turns uris and emails
// detected in document into links
export function linkRule(markType: MarkType) {
  return LinkInputRule(linkRegex, markType, (match: RegExpMatchArray) => ({
    type: match[2] == '@' ? 'email' : 'uri',
  }))
}

// : (NodeType) → InputRule
// Given a blockquote node type, returns an input rule that turns `"> "`
// at the start of a textblock into a blockquote.
export function blockQuoteRule(nodeType: NodeType) {
  return wrappingInputRule(/^\s*>\s$/, nodeType)
}

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a number
// followed by a dot at the start of a textblock into an ordered list.
export function orderedListRule(nodeType: NodeType) {
  return wrappingInputRule(
    /^(\d+)\.\s$/,
    nodeType,
    (match) => ({ order: +match[1] }),
    (match, node) => node.childCount + node.attrs.order == +match[1],
  )
}

// : (NodeType) → InputRule
// Given a list node type, returns an input rule that turns a bullet
// (dash, plush, or asterisk) at the start of a textblock into a
// bullet list.
export function bulletListRule(nodeType: NodeType) {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType)
}

// : (NodeType) → InputRule
// Given a code block node type, returns an input rule that turns a
// textblock starting with three backticks into a code block.
export function codeBlockRule(nodeType: NodeType) {
  return textblockTypeInputRule(/^```$/, nodeType)
}

// : (NodeType, number) → InputRule
// Given a node type and a maximum level, creates an input rule that
// turns up to that number of `#` characters followed by a space at
// the start of a textblock into a heading whose level corresponds to
// the number of `#` signs.
export function headingRule(nodeType: NodeType, maxLevel: number) {
  return textblockTypeInputRule(
    new RegExp('^(#{1,' + maxLevel + '})\\s$'),
    nodeType,
    (match) => ({ level: match[1].length }),
  )
}

// : (Schema) → Plugin
// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export function buildInputRules(schema: Schema) {
  const rules = smartQuotes.concat(ellipsis, emDash)
  let type
  if ((type = schema.nodes.blockquote)) rules.push(blockQuoteRule(type))
  if ((type = schema.nodes.ordered_list)) rules.push(orderedListRule(type))
  if ((type = schema.nodes.bullet_list)) rules.push(bulletListRule(type))
  if ((type = schema.nodes.code_block)) rules.push(codeBlockRule(type))
  if ((type = schema.nodes.heading)) rules.push(headingRule(type, 6))
  if ((type = schema.marks.link)) rules.push(linkRule(type))
  return inputRules({ rules })
}
