<script setup lang="ts">
import { useRouter } from 'vue-router'
import { watch, ref, computed, reactive } from 'vue'

import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { Button, DropDownItem, EmptyView, DropDown } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { useCollabStore } from './store'
import AssetRow from './components/AssetRow.vue'
import AssetFormRow from './components/AssetFormRow.vue'
import MembersPreview from './components/MembersPreview.vue'
import BulkActions from './components/actions/BulkActions.vue'

const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore
const store = useCollabStore()
const router = useRouter();

const folderId = ref(Number(router.currentRoute.value.params.folderId))

store.loadAsset(folderId.value)

store.loadAssets({
  include: 'members,subAssetsCount,membersCount,parent',
  'filter[parent_id]': folderId.value,
})

watch(
  () => router.currentRoute.value.params.folderId,
  () => {
    folderId.value = Number(router.currentRoute.value.params.folderId)

    if (!store.folder || store.folder.id !== folderId.value) {
      store.loadAsset(folderId.value)
      store.loadAssets({
        include: 'members,subAssetsCount,parent',
        'filter[parent_id]': folderId.value,
      })
    }
  },
)

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Collab`,
    url: { name: 'Collab' },
  },
  {
    title: store?.folder?.title ?? '',
    url: { name: 'CollabFolder', params: {folderId: folderId.value} },
  },
])

const form = reactive<{
  isOpen: boolean,
  type: 'file' | 'folder',
  title: string
}>({
  isOpen: false,
  type: 'file',
  title: ''
})

const handleCreate = (title: string) => {
  store.createAsset({title, parentId: folderId.value, type: form.type})
    .then(() => {
      form.isOpen = false
    })
}

const menu: DropDownItem[] = [
  {
    id: 'folder',
    icon: 'folder2',
    title: 'Folder',
    handler: () => {
      form.title = '';
      form.type = 'folder';
      form.isOpen = true;
    }
  },
  {
    id: 'file',
    icon: 'fileBend',
    title: 'File',
    handler: () => {
      form.title = '';
      form.type = 'file';
      form.isOpen = true;
    },
  },
]

</script>
<template>
  <Page>
     <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />

      <div :class="$style.pageActions">
        <DropDown :width="160" :menu="menu" :close-on-select="true">
          <Button size="2" variant="outline" theme="neutral" icon="plusSmall">Create new </Button>
        </DropDown>
      </div>
    </template>

    <EmptyView
      v-if="!form.isOpen && store.assets.length === 0 && store.isStatus('loaded')"
      :class="$style.empty"
      icon="fileBendOutlined"
      title="You don’t have any files yet."
      description="Let’s add your first file!"
    >
      <Button
        size="2"
        variant="alternative"
        theme="neutral"
        @click="
          form.isOpen = true;
          form.title = ''
          form.type = 'file'
        "
        >
        Create new file
      </Button>
    </EmptyView>

    <template v-else>
      <div :class="$style.heading">
        <div :class="$style.info">
          <h2 :class="$style.title">{{ store.folder?.title }}</h2>
          <h2 :class="$style.count">{{ store.folder?.subAssetsCount }} files</h2>
        </div>

        <MembersPreview :members="store.folder?.members ?? []"/>
      </div>

      <table :class="$style.table">
        <thead>
          <BulkActions
            v-if="store.selectedIds.length"
            :selected-ids="store.selectedIds"
            :total="store.assets.length"
            @check-all="(all: boolean) =>  store.selectedIds = all ? store.assets.map(asset => asset.id): []"
          />
        </thead>

        <tbody>
          <AssetRow
            v-for="asset in store.assets.filter(asset => asset.type === 'folder')"
            :key="asset.id" :item="asset"
            :checked="store.selectedIds.includes(asset.id)"
          />

          <AssetFormRow
            v-if="form.isOpen && form.type ==='folder'"
            :type="form.type"
            @submit="handleCreate"/>

          <AssetRow
            v-for="asset in store.assets.filter(asset => asset.type === 'file')"
            :key="asset.id"
            :item="asset"
            :checked="store.selectedIds.includes(asset.id)"
          />

          <AssetFormRow v-if="form.isOpen && form.type ==='file'" :type="form.type" @submit="handleCreate"/>
        </tbody>
      </table>
    </template>
  </Page>
</template>

<style module lang="scss">
@import url(./components/common.module.scss);
.heading {
   margin: var(--page-margin) 0px;
   display: flex;
   justify-content: space-between;
   align-items: center;

  .info {
    display: flex;
    align-items: center;
    gap: 12px;

    .title {
      @extend .semibold-6;
    }

    .count {
      @extend .regular-3;
      color: var(--neutral-11);
    }
  }
}

.empty {
  margin-top: 64px;
}

.table {
  width: 100%;

  tr {
    td {
      padding: 12px 0px;
      vertical-align: middle;
      transition: background-color 0.1s;
      padding-left: 16px;

      &:last-child {
        padding-right: 16px;
      }
    }

    &:hover {
      td {
        background-color: var(--neutral-alpha-3);
      }
    }
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid var(--neutral-alpha-3);
    }
  }
}
</style>
