<script setup lang="ts">
import { Avatar, Button } from '@common/index.ts'
import type { TaskComment } from '../../models/taskComment.ts'
import { toRefs } from 'vue'
import { ISODate } from '@/utils/helpers.ts'
import { renderMarkdown } from '@/utils/inbox.ts'
import Attachments from '@modules/tasks/components/Attachment/Attachments.vue'

const props = defineProps<{
  comment: TaskComment
}>()
const { comment } = toRefs(props)

const emit = defineEmits<{
  (e: 'reply', data: TaskComment): void
}>()

const handleReply = () => {
  emit('reply', comment.value)
}
</script>
<template>
  <div :class="$style['comment-item']">
    <Avatar :size="32" :title="comment.user.name" :src="comment.user.avatar" />
    <div :class="$style['comment-content']">
      <span :class="$style['author-area']">
        <p :class="$style.name">{{ comment.user.name }}</p>
        <p :class="$style.time">
          {{ ISODate(comment.createdAt, { format: 'LLL dd' }) }}
        </p>
      </span>
      <p
        v-if="comment.parent"
        :class="$style['comment-parent']"
        v-html="renderMarkdown(comment.parent.content)"
      />
      <p
        :class="$style['comment-text']"
        v-html="renderMarkdown(comment.content)"
      />
      <Attachments
        :removable="false"
        :images="comment.attachments.images"
        :files="comment.attachments.files"
      />
    </div>
    <Button
      v-if="!comment.parentId"
      :class="$style['reply-button']"
      variant="ghost"
      theme="neutral"
      size="2"
      @click="handleReply"
      >Reply</Button
    >
  </div>
</template>
<style module lang="scss">
.activity-item {
  @extend .medium-2;
  color: var(--neutral-11);

  b {
    color: var(--neutral-12);
  }
}

.comment-item {
  display: flex;
  column-gap: 12px;
  a {
    color: blue;
  }
  .comment-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .author-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;

      .name {
        @extend .medium-3;
      }
      .time {
        @extend .medium-2;
        color: var(--neutral-9);
      }
    }

    .comment-parent {
      @extend .regular-3;
      padding-left: 8px;
      border-left: 2px solid var(--neutral-alpha-4);
    }

    .comment-text {
      @extend .regular-3;
    }
  }

  .reply-button {
    color: var(--neutral-alpha-11);

    &:hover {
      background-color: transparent;
      color: var(--neutral-alpha-12);
    }
  }
}
</style>
