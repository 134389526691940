<script setup lang="ts">
import { computed } from 'vue'
import { Breadcrumbs, Page, type Crumb } from '@/components/common/layout'
import { Button, DropDownItem, EmptyView, DropDown } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { reactive } from 'vue'
import { useCollabStore } from './store'
import AssetRow from './components/AssetRow.vue'
import AssetFormRow from './components/AssetFormRow.vue'
import BulkActions from './components/actions/BulkActions.vue'

const workspaceStore = useWorkspaceStore()
const { workspace } = workspaceStore
const store = useCollabStore()

store.loadAssets({
  'include': 'members,membersCount,subAssetsCount,parent',
  'filter[no_parent]': true
})

const crumbs = computed<Crumb[]>(() => [
  {
    title: `${workspace?.title} Collab`,
    url: { name: 'Collab' },
  },
])

const form = reactive<{
  isOpen: boolean,
  type: 'file' | 'folder',
  title: string
}>({
  isOpen: false,
  type: 'file',
  title: ''
})

const handleCreate = (title: string) => {
      store.createAsset({title, type: form.type})
        .then(() => {
          form.isOpen = false
        })
}

const menu: DropDownItem[] = [
  {
    id: 'folder',
    icon: 'folder2',
    title: 'Folder',
    handler: () => {
      form.title = '';
      form.type = 'folder';
      form.isOpen = true;
    }
  },
  {
    id: 'file',
    icon: 'fileBend',
    title: 'File',
    handler: () => {
      form.title = '';
      form.type = 'file';
      form.isOpen = true;
    },
  },
]

</script>
<template>
  <Page>
     <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />

      <div :class="$style.pageActions">
        <DropDown :width="160" :menu="menu" :close-on-select="true">
          <Button size="2" variant="outline" theme="neutral" icon="plusSmall">Create new </Button>
        </DropDown>
      </div>
    </template>

    <EmptyView
      v-if="!form.isOpen && store.assets.length === 0 && store.isStatus('loaded')"
      :class="$style.empty"
      icon="fileBendOutlined"
      title="You don’t have any files yet."
      description="Let’s add your first file!"
    >
      <Button
        size="2"
        variant="alternative"
        theme="neutral"
        @click="
          form.isOpen = true;
          form.title = ''
          form.type = 'file'
        "
        >
        Create new file
      </Button>
    </EmptyView>

    <template v-else>
      <h2 :class="$style.heading">Files</h2>
      <table :class="$style.table">
        <thead>
        <BulkActions
          v-if="store.selectedIds.length"
          :selected-ids="store.selectedIds"
          :total="store.assets.length"
          @check-all="(all: boolean) =>  store.selectedIds = all ? store.assets.map(asset => asset.id): []"
        />
        </thead>
        <tbody>
          <AssetRow
            v-for="asset in store.assets.filter(asset => asset.type === 'folder')"
            :key="asset.id"
            :item="asset"
            :checked="store.selectedIds.includes(asset.id)"
            />

          <AssetFormRow
            v-if="form.isOpen && form.type ==='folder'"
            :type="form.type"
            @submit="handleCreate"/>

          <AssetRow
            v-for="asset in store.assets.filter(asset => asset.type === 'file')"
            :key="asset.id"
            :item="asset"
            :checked="store.selectedIds.includes(asset.id)"
          />

          <AssetFormRow v-if="form.isOpen && form.type ==='file'" :type="form.type" @submit="handleCreate"/>
        </tbody>
      </table>
    </template>
  </Page>
</template>

<style module lang="scss">
@import url(./components/common.module.scss);
.heading {
  @extend .semibold-6;
  margin: var(--page-margin) 0px;
}

.empty {
  margin-top: 64px;
}

.table {
  width: 100%;

  tr {
    td {
      padding: 12px 0px;
      vertical-align: middle;
      transition: background-color 0.1s;
      padding-left: 16px;

      &:last-child {
        padding-right: 16px;
      }
    }

    &:hover {
      td {
        background-color: var(--neutral-alpha-3);
      }
    }
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid var(--neutral-alpha-3);
    }
  }
}
</style>
