<script setup lang="ts">
import { useApplicationStore } from '@/store';
import { Avatar, Icon, Menu, MenuItem, MenuTitle } from '../common';

const appStore = useApplicationStore()


</script>
<template>
  <Menu :width="272">
    <MenuTitle>Logged in as</MenuTitle>
    <router-link v-slot="{ navigate }" custom :to="{ name: 'AccountSettings' }">
      <MenuItem
        :hide-on-click="true"
        :title="appStore.user?.name || ''"
        :subtitle="appStore.user?.email || ''"
        @click="navigate"
      >
        <template #icon>
          <Avatar
            :size="32"
            :src="appStore.user?.avatar"
            :title="appStore.user?.name"
          />
        </template>
        <template #action>
          <Icon :size="20" icon="settingsGear2" :class="$style.icon" />
        </template>
      </MenuItem>
    </router-link>
    <hr />
    <a href="mailto:hello@vanillaapps.com">
      <MenuItem :hide-on-click="true" title="Found a bug?" />
    </a>
    <a href="https://vanillaapps.com/terms" target="_blank">
      <MenuItem :hide-on-click="true" title="Terms & Policies" />
    </a>
    <hr />
    <MenuItem title="Logout" @click="appStore.signOut()"></MenuItem>
  </Menu>
</template>
<style module lang="scss">
.icon {
  color: var(--neutral-11);
}
.workspaceLogo {
  width: 24px;
  height: 24px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-3);
  color: var(--neutral-8);
}
</style>
