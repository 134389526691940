<script setup lang="ts">
import { UploadedFile } from '@/models/file.ts'
import { Button, FileField } from '@common/index.ts'
import Attachments from '@modules/tasks/components/Attachment/Attachments.vue'

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']

const acceptableFiles = [
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'video/mp4',
  'image/*',
]

const emit = defineEmits<{
  (e: 'removedImage', id: number): void
  (e: 'removedFile', id: number): void
}>()

const files = defineModel<(UploadedFile | File)[]>('files', { default: [] })
const images = defineModel<(UploadedFile | File)[]>('images', { default: [] })

const handleFiles = (data: File | File[]) => {
  const newFiles = data as File[]
  files.value = [
    ...files.value,
    ...newFiles.filter((f) => !imageTypes.includes(f.type)),
  ]

  images.value = [
    ...images.value,
    ...newFiles.filter((f) => imageTypes.includes(f.type)),
  ]
}

const onRemoveImage = (id: number) => {
  const image = images.value[id]

  if (!(image instanceof File)) {
    emit('removedImage', id)
  }

  images.value.splice(id, 1)
}

const onRemoveFile = (id: number) => {
  const file = files.value[id]

  if (!(file instanceof File)) {
    emit('removedFile', id)
  }

  files.value.splice(id, 1)
}
</script>

<template>
  <div>
    <Attachments
      :images="images"
      :files="files"
      :removable="true"
      @remove-file="onRemoveFile"
      @remove-image="onRemoveImage"
    />
    <div :class="$style.actions">
    <FileField
      multiple
      :accept="acceptableFiles.join(',')"
      @update:model-value="handleFiles"
    >
      <Button
        as="span"
        icon="paperclip2"
        size="2"
        variant="ghost"
        theme="neutral"
      />
    </FileField>
  </div>
  </div>
</template>

<style module lang="scss">
.images,
.files {
  display: flex;
  width: 80%;
  gap: 8px;
  margin-top: 8px;
  border-radius: 8px;
  padding: 8px;
  flex-wrap: wrap;
}
.actions {
  display:flex;
}
</style>
