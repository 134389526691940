<script setup lang="ts">
import { Avatar } from '@/components/common'
import Icon from '@/components/common/Icon.vue'
import { renderMarkdown } from '@/utils/inbox'
import { computed, toRefs } from 'vue'
import type Message from '../../models/message'
import { MessageType } from '../../models/message'
import Attachments from './Attachments.vue'
import Date from './Date.vue'
import Email from './Email.vue'
import System from './System.vue'

const props = defineProps<{
  message: Message
}>()

const { message } = toRefs(props)

const hasText = computed(() => {
  const { message: m } = message.value
  return m !== null
})
const name = computed(() => {
  const { operator, contact } = message.value
  if (operator) {
    return operator.name
  } else if (contact) {
    return contact.name || contact.email
  }
  return 'Automated message'
})

const avatar = computed(() => {
  const { operator } = message.value
  if (operator) return operator.avatar
  return undefined
})

const isSystem = () => {
  return [
    MessageType.chatEnded,
    MessageType.chatReopened,
    MessageType.operatorAssigned,
    MessageType.operatorRemoved,
  ].includes(message.value.messageType)
}
</script>
<template>
  <Date v-if="message.date">{{ message.date }}</Date>
  <!-- Note -->
  <li
    v-if="message.messageType === MessageType.note"
    :id="`thread-note-${message.id}`"
    tabindex="-1"
    :class="[$style.container, $style.note]"
  >
    <div :class="$style.noteAvatar">
      <Icon icon="note1" :size="32" />
    </div>
    <div :class="$style.content">
      <div :class="$style.title">
        <span :class="$style.name">Internal note</span>
        <span :class="$style.time">{{ name }} {{ message.time }}</span>
      </div>
      <p :class="$style.message" v-html="renderMarkdown(message.message)"></p>
      <Attachments :attachment="message.attachments" />
    </div>
  </li>

  <!-- Normal Message -->
  <li v-else :class="$style.container">
    <Avatar
      v-if="
        !(
          message.messageType === MessageType.operatorAssigned ||
          message.messageType === MessageType.operatorRemoved
        )
      "
      :size="32"
      :title="name"
      :src="avatar"
    />
    <div :class="$style.content">
      <div :class="$style.title">
        <span :class="$style.name">{{ name }}</span>
        <span :class="$style.time">{{ message.time }}</span>
      </div>
      <System v-if="isSystem()" :message="message" />
      <Email
        v-else-if="message.attachments && message.attachments.email"
        :class="$style.message"
        :email="message.attachments.email"
      />
      <p
        v-else-if="hasText"
        :class="$style.message"
        v-html="renderMarkdown(message.message)"
      ></p>
      <Attachments :attachment="message.attachments" />
    </div>
  </li>
</template>
<style module lang="scss">
.container {
  display: flex;
  margin-bottom: 24px;

  &.note {
    border-radius: 8px;
    background: var(--warning-alpha-2);
    padding: 8px;
    align-self: flex-start;

    &:focus {
      outline: 2px solid var(--warning-9);
      outline-offset: 2px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  min-width: 0;
}
.title {
  display: flex;
  align-items: center;
}

.name {
  @extend .medium-3;
  margin-right: 8px;
}

.time {
  @extend .medium-2;
  color: var(--neutral-9);
}

.message {
  @extend .regular-3;
  word-wrap: break-word;
  a {
    color: blue;
  }
}

.noteAvatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  color: var(--warning-11);
  background-color: var(--warning-3);
  box-shadow: 0px -4px 8px -2px var(--neutral-alpha-3) inset;
  flex-shrink: 0;
}
</style>
