<script setup lang="ts">
import { ref } from 'vue'
import { useWorkspaceStore } from '@/store/workspace'
import { Asset } from '@/modules/collab/models/asset'
import Icon from '@/components/common/Icon.vue'
import Button from '@/components/common/Button.vue'
import Skeleton from '@/components/common/Skeleton.vue'

const {api, isStatus} = useWorkspaceStore()

const props = defineProps<{folder?: Asset | null, item?: Asset}>()

const emit = defineEmits<{
  (e: 'submit', id: number): void
  (e: 'cancel'): void
}>()

const folder = ref<Asset | null | undefined>(props.folder)
const folders = ref<Asset[]>([]);
const selected = ref<number>(props.folder?.parentId ?? 0)

const loadFolders = (folderId?: number | undefined | null) => {
  api.collab
      .loadAssets({
        'filter[type]': 'folder',
        'include': 'foldersCount,parent',
        ...(folderId) ? { 'filter[parent_id]': folderId }: { 'filter[no_parent]': true }
      })
      .then(response => folders.value = response)
      .catch(error => console.log(error))
}

loadFolders(props.folder?.id)

const onClickFolder = (item: Partial<Asset>) => {
  if (item.foldersCount && item.id) {
    folder.value = item as Asset

    loadFolders(item.id)
  }
}

const onBackDirectory = () => {
  folder.value = folder.value?.parent ?? null
  loadFolders(folder.value?.parentId)
}

</script>
<template>
  <div :class="['popup-content', $style.container]">
    <div :class="$style.directory" v-if="folder">
      <Icon icon="chevronLeftSmall" :size="16" style="cursor: pointer; color: var(--neutral-alpha-11, #0002119D);" @click="onBackDirectory"/>
      <span>{{ folder.title }}</span>
    </div>

    <div v-if="isStatus('loading')">
      <Skeleton v-for="n in 5" :key="n" :height="20" />
    </div>

    <ul :class="$style.list" v-else>
      <li v-if="item?.parentId !== null && folder === null">
        <div :class="$style.details" @click="() => onClickFolder({id: 0, title: 'Home', 'icon': 'house'})">
          <Icon
            :icon="'house'"
            :size="18"
            :style="`color: '#1e1f24'`"
          />

          <span :class="$style.title">Home</span>
        </div>

        <input type="radio" name="parent_id" v-model="selected" :value="0"/>
      </li>

      <li v-for="folder in folders.filter(folder => folder.id !== item?.id)" :key="folder.id">
        <div :class="$style.details" @click="() => onClickFolder(folder)">
          <Icon
            :icon="folder.icon ?? 'folder2'"
            :size="18"
            :style="`color: ${folder.color ?? '#1e1f24'}`"
          />

          <span :class="$style.title">{{ folder.title }}</span>
        </div>

        <input type="radio" name="parent_id" v-model="selected" :value="folder.id" :disabled="folder.id === item?.parentId"/>
      </li>
    </ul>
  </div>

    <div :class="['popup-content', 'popup-actions']">
      <Button theme="neutral" variant="outline" size="3" @click="emit('cancel')">Cancel</Button>
      <Button theme="neutral" variant="solid" size="3" @click="emit('submit', selected)">Move</Button>
    </div>
</template>
<style module lang="scss">
.container {
  width: 420px;
}

.directory {
  @extend .semibold-3;
  color: var(--neutral-12);
  background-color: var(--neutral-3);
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.list {
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      padding-top: 12px;
    }

    &:not(:last-child) {
      padding-bottom: 12px;
    }

    .details {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      cursor: pointer;

      .title {
        @extend .medium-3;
      }
    }

  }
}
</style>
