import type { AxiosInstance } from 'axios'
import type { AppKey } from '@/models/app'
import type { Tag } from '@/models/tag'

export class TagService {
  constructor(private instance: AxiosInstance) {}

  createTag = (name: string, namespace: AppKey) =>
    this.instance
      .post<Tag>(`/tags`, { name, namespace })
      .then((response) => response.data)

  loadTags = (namespace: AppKey) =>
    this.instance
      .get<Tag[]>(`/tags`, { params: { namespace } })
      .then((response) => response.data)
  LoadTagsWithCount = (namespace: AppKey) =>
    this.instance
      .get<Tag[]>(`/task/tags`, { params: { namespace, count: true } })
      .then((response) => response.data)
  deleteTag = (id: number, namespace: AppKey) =>
    this.instance
      .delete(`/tags/${id}`, { params: { namespace } })
      .then((response) => response.data)

  editTag = (id: number, name: string, namespace: AppKey) =>
    this.instance
      .put<Tag>(`/tags/${id}`, { name, namespace })
      .then((response) => response.data)
}
