import { InboxService } from '@/modules/inbox/api'
import { CRMService } from '@/modules/crm/api'
import { TasksService } from '@/modules/tasks/api'
import { CollabService } from '@/modules/collab/api'

import axios, { type AxiosInstance } from 'axios'
import { AccountService } from './account'
import { AuthService } from './auth'
import type { Pagination } from './types'
import { WorkspaceService } from './workspace'
import { CompanyService } from './company'
import { TagService } from './tag'

export default class Api {
  client: AxiosInstance

  auth: AuthService
  account: AccountService
  company: CompanyService
  workspace: WorkspaceService
  tag: TagService

  inbox: InboxService
  crm: CRMService
  task: TasksService
  collab: CollabService

  constructor(baseUrl?: string) {
    // build client
    this.client = axios.create({
      baseURL: baseUrl ?? import.meta.env.VITE_API_HOST,
      withCredentials: true,
      withXSRFToken: true,
    })

    this.auth = new AuthService(this.client)
    this.account = new AccountService(this.client)
    this.company = new CompanyService(this.client)
    this.workspace = new WorkspaceService(this.client)
    this.tag = new TagService(this.client)

    this.inbox = new InboxService(this.client)
    this.crm = new CRMService(this.client)
    this.task = new TasksService(this.client)
    this.collab = new CollabService(this.client)
  }

  loadNextPage = <T>(url: string) => {
    return this.client.get<Pagination<T>>(url).then((response) => response.data)
  }
}
