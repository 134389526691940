import type { AxiosInstance } from 'axios'
import User from '@/models/user'
import { Asset } from '../models/asset'
import { LoadAssetsParams } from './types'


export class CollabService {
  constructor(private instance: AxiosInstance) {}

  loadAssets = (params?: LoadAssetsParams) =>
    this.instance
      .get<Asset[]>(`/collab/assets`, { params })
      .then((response) => response.data)

  createAsset = (data: Partial<Asset>) =>
    this.instance
      .post<Asset>(`/collab/assets`, data)
      .then((response) => response.data)

  updateAsset = (
    id: number,
    payload: Partial<Asset>,
  ) =>
    this.instance
      .put<Asset>(`/collab/assets/${id}`, payload)
      .then((response) => response.data)
      .catch((error) => console.log(error))

  deleteAsset = (id: number) =>
    this.instance.delete(`/collab/assets/${id}`)
      .then((response) => response.data)
      .catch((error) => console.log(error))

  loadAsset = (id: number) =>
    this.instance
      .get<Asset>(`/collab/assets/${id}`)
      .then((response) => response.data)

  favorite = (id: number) =>
    this.instance
      .post(`/collab/assets/${id}/favorite`)
      .then((response) => response.data)
      .catch((error) => console.log(error))

  unFavorite = (id: number) =>
    this.instance
      .delete(`/collab/assets/${id}/favorite`)
      .then((response) => response.data)
      .catch((error) => console.log(error))

  loadAssetMembers = (assetId: number) =>
    this.instance
      .get<User[]>(`/collab/assets/${assetId}/members`)
      .then((response) => response.data)
      .catch((error) => console.log(error))

  createAssetMember = (assetId: number, memberId: number) =>
    this.instance
      .post<User>(`/collab/assets/${assetId}/members`, { memberId })
      .then((response) => response.data)
      .catch((error) => console.log(error))

  deleteAssetMember = (assetId: number, userId: number) =>
    this.instance
      .delete(`/collab/assets/${assetId}/members/${userId}`)
      .then((response) => response.data)
      .catch((error) => console.log(error))

  deleteAssets = (items: number[]) =>
    this.instance
      .delete('/collab/assets/bulk-delete', {data: {items} })
      .then((response) => response.data)
      .catch((error) => console.log(error))

  updateAssets = (data: {items: number[], parent_id?: number | null},) =>
    this.instance
      .post('/collab/assets/bulk-update', data)
      .then((response) => response.data)
      .catch((error) => console.log(error))
}
