import { marked } from 'marked'
import markedLinkifyIt from 'marked-linkify-it'

const preprocessMarkdown = (markdown: string) => {
  return markdown.replace(/\[user-id="(\w+)" name="(.+?)"\]/g, '**@$2**')
}

export const renderMarkdown = (markdown: string | null, breaks = true) => {
  if (!markdown) return ''
  const preprocessedMarkdown = preprocessMarkdown(markdown)
  const renderer = new marked.Renderer()

  // Modify the link rendering to include target="_blank"
  renderer.link = (href, title, text) => {
    return `<a href="${href}" class="marked-link" title="${title || ''}" target="_blank">${text}</a>`
  }
  marked.use(markedLinkifyIt({}, {}))
  return marked.parse(preprocessedMarkdown, { breaks, renderer })
}
