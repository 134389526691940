<script setup lang="ts">
import { ref } from 'vue';
import { Icon } from '@/components/common'
defineProps<{ type: 'folder' | string}>()
const emit = defineEmits<{
  (e: 'submit', title: string): void
}>()

const title = ref<string>("")

const onSubmit = () => {
  if (title.value) {
    emit('submit', title.value)
  }
}
</script>

<template>
<tr>
    <td width="32">
              <span :class="$style.pipelineIcon">
                <Icon icon="plusSmall" :size="20" />
              </span>
            </td>

            <td colspan="4">
              <input
                v-model.trim="title"
                v-focus
                :class="$style.textField"
                :placeholder="`add name for new ${type}`"
                @keypress.enter="onSubmit"
              />
            </td>
          </tr>
</template>


<style module lang="scss">
@import url(./common.module.scss);
</style>
