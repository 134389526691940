<script setup lang="ts">
import { computed, nextTick, reactive, ref, toRefs, watchEffect } from 'vue'
import { Button, Confirm, type DropDownItem, TextField } from '@common/index.ts'
import { useTasksTeamStore } from '@modules/tasks/teamStore.ts'
import StageIndicator from '@modules/tasks/components/StageIndicator.vue'
import type { TaskStatus } from '@modules/tasks/models/taskStatus.ts'
import { DropDown } from '@/components/common'

const props = defineProps<{
  status: TaskStatus
  count: number
  enableAddTask?: boolean
  allowedActions: string[]
}>()

const store = useTasksTeamStore()
const { status, allowedActions, enableAddTask = true } = toRefs(props)
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const inputRef = ref<{ focus: () => void } | null>(null)
const state = reactive({
  renameValue: '',
  renaming: false,
})

watchEffect(() => {
  if (state.renaming) {
    nextTick(() => {
      inputRef.value?.focus()
    })
  }
})

const handleNameUpdate = () => {
  if (status.value.name === state.renameValue) {
    state.renaming = false
    return
  }
  store.updateStatus(status.value, state.renameValue)
  state.renaming = false
}

const handleNameCancel = () => {
  state.renaming = false
}

const actionsMenu: DropDownItem[] = [
  {
    id: 'rename',
    title: 'Rename',
    handler: () => {
      state.renameValue = status.value.name
      state.renaming = true
    },
  },
  {
    id: 'hide',
    type: 'primary',
    title: status.value.isVisible ? 'Hide' : 'Show',
    handler: () =>
      store.toggleStatusVisibility(status.value.id, !status.value.isVisible),
  },
  {
    id: 'delete',
    type: 'destructive',
    title: 'Delete',
    handler: () => {
      confirmPopup.value?.show({
        title: 'Delete column',
        message: status.value.tasksCount
          ? `You're about to delete column with ${status.value.tasksCount} tasks in it. All tasks in this column will be removed from this project. Are you sure you want to proceed?`
          : `You're about to delete column. Are you sure you want to proceed?`,
        actions: [
          {
            title: 'Cancel',
            theme: 'neutral',
            variant: 'ghost',
          },
          {
            title: `Delete Column`,
            theme: 'danger',
            handler: () => store.deleteStatus(status.value.id),
          },
        ],
      })
    },
  },
]

const statusPaddingLeft = computed(() => {
  if (state.renaming) return '0px'
  return '8px'
})
</script>

<template>
  <div :class="$style.header">
    <Confirm ref="confirmPopup" />

    <div v-if="state.renaming" :class="$style.title">
      <TextField
        ref="inputRef"
        v-model="state.renameValue"
        :class="$style.textField"
        variant="soft"
        @keyup.enter="handleNameUpdate"
        @keyup.escape="handleNameCancel"
        @blur="handleNameUpdate"
      >
      </TextField>
      <Button
        icon="crossSmall"
        variant="ghost"
        theme="neutral"
        size="2"
        @click="handleNameCancel"
      />
      <Button
        icon="squareCheck"
        variant="ghost"
        theme="neutral"
        size="2"
        @click="handleNameUpdate"
      />
    </div>
    <div
      v-else
      :class="$style.title"
      @click="
        () => {
          state.renaming = true
          state.renameValue = status.name
        }
      "
    >
      <StageIndicator
        :size="16"
        :stage="status.order"
        :total-stages="store.statuses.length"
      />
      {{ status.name }}

      <span :class="$style.count">{{ count }}</span>
    </div>
    <div v-if="!state.renaming" :class="$style.actions">
      <Button
        v-if="enableAddTask"
        size="2"
        theme="neutral"
        variant="ghost"
        icon="plusSmall"
        @click="
          () => {
            store.selectedStatus = status
            store.isAddTaskOpen = true
          }
        "
      />
      <DropDown
        :width="160"
        :menu="
          actionsMenu.filter((action) =>
            allowedActions.includes(action.id as string),
          )
        "
        :close-on-select="true"
      >
        <Button
          size="2"
          theme="neutral"
          variant="ghost"
          icon="dotGrid1x3Horizontal"
        />
      </DropDown>
    </div>
  </div>
</template>

<style module lang="scss">
.header {
  @extend .medium-3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-left: v-bind(statusPaddingLeft);
  color: var(--neutral-alpha-12);
  column-gap: 8px;

  .title {
    flex-grow: 1;

    .count {
      @extend .regular-3;
      color: var(--neutral-11);
    }
  }

  .title,
  .actions {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .textField {
    width: 100%;
    &:focus-within {
      background-color: var(--neutral-alpha-3);
      outline: none;
      border: none;
    }
    input {
      &:focus {
        background-color: unset;
      }
    }
  }
}
</style>
