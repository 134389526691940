import { useWorkspaceApi } from '@/composables/api'
import type { Pagination } from '@/utils/api/types'
import { defineStore } from 'pinia'
import type { Team } from './models/team'
import type { Tag } from '@/models/tag'
import type Api from '@/utils/api'
import { TaskSearch, UpdateTaskRequest } from './types'
import { Task } from './models/task'
import { generateFilters } from '@/utils/tasks'
import icons from '@/assets/icons'
import { Menu } from '@/pages/Dashboard/constants'
import { useApplicationStore } from '@/store'

export interface UpdateTeamRequest {
  name?: string
  color?: string
  icon?: keyof typeof icons
}

interface TasksStore extends ReturnType<typeof useWorkspaceApi> {
  pagination?: Pagination<Team>
  tasks: Task[]
  teams: Team[]
  tags: Tag[]
  api: Api
}

export const useTasksStore = defineStore('tasks', {
  state: (): TasksStore => ({
    teams: [],
    tags: [],
    tasks: [],
    ...useWorkspaceApi(),
  }),
  getters: {
    menuItems(): Menu {
      const { user } = useApplicationStore()

      const myTeams = user
        ? this.teams.filter((team) =>
            team.members?.some((m) => m.id === user.id),
          )
        : []

      return {
        children: [
          {
            icon: 'bulletList',
            title: 'My tasks',
            url: { name: 'TasksMine' },
          },
          {
            icon: 'arCube1Filled',
            title: 'Teams',
            url: { name: 'TasksTeams' },
            children: [
              ...myTeams.map((team) => ({
                icon: team.icon || 'arCube1',
                title: team.name,
                url: { name: 'TasksTeam', params: { teamId: team.id } },
              })),
            ],
          },
          {
            icon: 'settingsGear2',
            title: 'Settings',
            url: { name: 'TaskSettings' },
          },
        ],
      }
    },
  },
  actions: {
    createTeam(name: string) {
      return this.api.task.createTeam(name).then((data) => {
        this.teams.push(data)
      })
    },
    updateTeam(id: number, payload: UpdateTeamRequest) {
      const index = this.teams.findIndex((p) => p.id === id)

      if (index !== -1) {
        const updatedTeam = {
          ...this.teams[index],
          ...payload,
        }
        this.teams[index] = updatedTeam
      }

      return this.api.task.updateTeam(id, payload)
    },
    deleteTeam(id: number) {
      const updated = [...this.teams].filter((c) => c.id !== id)
      this.teams = updated

      return this.api.task.deleteTeam(id)
    },
    loadTeams() {
      return this.api.task.loadTeams().then((data) => {
        this.teams = data
      })
    },
    loadTags() {
      return this.api.tag.loadTags('projectManagement').then((data) => {
        this.tags = data
      })
    },
    loadTagsWithCount() {
      return this.api.tag
        .LoadTagsWithCount('projectManagement')
        .then((data) => {
          this.tags = data
        })
    },
    createTag(name: string) {
      return this.api.tag.createTag(name, 'projectManagement').then((data) => {
        this.tags.push(data)
      })
    },
    deleteTag(id: number) {
      const updated = [...this.tags].filter((c) => c.id !== id)
      this.tags = updated

      return this.api.tag.deleteTag(id, 'projectManagement')
    },
    editTag(id: number, name: string) {
      return this.api.tag
        .editTag(id, name, 'projectManagement')
        .then((data) => {
          const index = this.tags.findIndex((p) => p.id === id)
          index !== -1 && (this.tags[index] = data)
        })
    },
    addTeamMember(teamId: number, userId: number) {
      const index = this.teams.findIndex((c) => c.id === teamId)
      Object.assign(this.teams[index], {
        membersCount: (this.teams[index].membersCount || 0) + 1,
      })

      return this.api.task.createTeamMember(teamId, userId)
    },
    deleteTeamMember(teamId: number, userId: number) {
      const index = this.teams.findIndex((c) => c.id === teamId)
      Object.assign(this.teams[index], {
        membersCount: (this.teams[index].membersCount || 1) - 1,
      })

      return this.api.task.deleteTeamMember(teamId, userId)
    },
    loadMyTasks(search?: TaskSearch) {
      const filters = generateFilters(search, true)
      this.api.task.loadTasks(filters).then((data) => {
        this.tasks = data
      })
    },
    updateTask(
      taskId: number,
      payload: UpdateTaskRequest,
      isAssigneeUpdate = false,
    ) {
      const task = {
        ...{
          ...(payload.status && {
            statusId: payload.status.id,
            status: payload.status,
          }),
          ...(isAssigneeUpdate && {
            assignees: payload.assignee ? [payload.assignee] : [],
          }),
          ...(payload.priority && { priority: payload.priority.id }),
        },
      }

      const newTasks = isAssigneeUpdate
        ? this.tasks.filter((task) => task.id !== taskId)
        : this.tasks

      const tasks = newTasks.map((item) =>
        item.id === taskId ? { ...item, ...task } : item,
      )
      this.tasks = tasks

      this.api.task.updateTask(
        {
          ...(payload.status && { statusId: payload.status.id }),
          ...(isAssigneeUpdate && {
            assignees: payload.assignee ? [payload.assignee.id] : [],
          }),
          ...(payload.priority && { priority: payload.priority.id }),
        },
        taskId,
      )
    },
  },
})
