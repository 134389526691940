<script setup lang="ts">
import {
  Button,
  Confirm,
  DropDown,
  EmptyView,
  Icon,
  TextField,
} from '@/components/common'
import { Tag } from '@/models/tag'
import { useTasksStore } from '@/modules/tasks/store'
import { ISODate } from '@/utils/helpers'
import { computed, nextTick, onMounted, reactive, ref, watchEffect } from 'vue'

interface TagState {
  tagName: string
  isAddingTag: boolean
  isEditingTag: boolean
  tagId: number | null
}

const store = useTasksStore()
onMounted(async () => {
  if (store.tags.length === 0) {
    await store.loadTagsWithCount()
  }
})

const tagState = reactive<TagState>({
  tagName: '',
  isAddingTag: false,
  isEditingTag: false,
  tagId: null,
})

const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const search = ref<string>('')
const tagInputRef = ref<{ focus: () => void } | null>(null)
const displayedItems = computed(() => {
  const filteredTags = store.tags.filter(
    (tag) =>
      tag.name.toLowerCase().includes(search.value.toLowerCase()) &&
      tag.id !== tagState.tagId,
  )

  return filteredTags
})

watchEffect(() => {
  if (tagState.isAddingTag || tagState.isEditingTag) {
    nextTick(() => {
      tagInputRef.value?.focus()
    })
  }
})

const createActionsMenu = (tag: Tag) => [
  {
    id: 'edit',
    title: 'Edit',
    handler() {
      tagState.tagId = tag.id
      tagState.tagName = tag.name
      tagState.isEditingTag = true
    },
  },
  {
    id: 'delete',
    title: 'Delete',
    handler: () => {
      confirmPopup.value?.show({
        title: 'Delete tag',
        message: tag.task_usage
          ? `You're about to delete this tag, which is used in ${tag.task_usage} tasks. Are you sure you want to proceed?`
          : `You're about to delete this tag. Are you sure you want to proceed?`,
        actions: [
          {
            title: 'Cancel',
            theme: 'neutral',
            variant: 'ghost',
          },
          {
            title: `Delete tag`,
            theme: 'danger',
            handler: () => store.deleteTag(tag.id),
          },
        ],
      })
    },
  },
]

const addTag = () => {
  if (!tagState.tagName.trim()) return
  store.createTag(tagState.tagName.trim())
  resetTagState()
}

const editTag = () => {
  if (!tagState.tagId || !tagState.tagName.trim()) return
  store.editTag(tagState.tagId, tagState.tagName.trim())
  resetTagState()
}
const addNotFoundTask = () => {
  tagState.isAddingTag = true
  tagState.tagName = search.value
  search.value = ''
}
const resetTagState = () => {
  tagState.isAddingTag = false
  tagState.isEditingTag = false
  tagState.tagName = ''
  tagState.tagId = null
}
</script>

<template>
  <Confirm ref="confirmPopup" />
  <div :class="$style.section">
    <div :class="$style.title">Tasks tags</div>
    <EmptyView
      v-if="
        store.isStatus('loaded') && !store.tags.length && !tagState.isAddingTag
      "
      title="You don't have any tags yet."
      icon="tagSale"
      description="Tags are a great way to organize your tasks"
    >
      <Button
        size="2"
        variant="outline"
        theme="neutral"
        icon="plusSmall"
        @click="
          () => {
            tagState.isAddingTag = true
            tagState.tagName = ''
          }
        "
        >Add tag</Button
      >
    </EmptyView>
    <template v-if="store.tags.length || tagState.isAddingTag">
      <div :class="$style.searchBarContainer">
        <TextField v-model="search" placeholder="Search tag" size="1">
          <template #pre>
            <Icon
              icon="magnifyingGlass"
              :size="18"
              :class="$style.searchIcon"
            />
          </template>
        </TextField>
        <Button
          size="2"
          theme="neutral"
          variant="soft"
          @click="
            () => {
              tagState.tagName = ''
              tagState.isAddingTag = true
            }
          "
        >
          Add new tag
        </Button>
      </div>
      <p :class="$style.tagCount">
        {{ store.tags.length }}
        {{ store.tags.length > 1 || store.tags.length == 0 ? 'tags' : 'tag' }}
      </p>
      <table :class="$style.tagsTable">
        <thead>
          <tr>
            <th :class="$style.nameColumn">Name</th>
            <th>Usage</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="tagState.isAddingTag || tagState.isEditingTag">
            <td colspan="4">
              <div :class="$style.addTagWrapper">
                <TextField
                  ref="tagInputRef"
                  v-model="tagState.tagName"
                  placeholder="add name for new tag"
                  :class="$style.textField"
                  variant="outline"
                  @keyup.enter="
                    () => (tagState.isAddingTag ? addTag() : editTag())
                  "
                >
                </TextField>
                <div>
                  <Button
                    size="2"
                    icon="crossSmall"
                    variant="ghost"
                    theme="neutral"
                    @click="resetTagState"
                  ></Button>
                  <Button
                    size="2"
                    icon="squareCheck"
                    variant="ghost"
                    theme="neutral"
                    @click="() => (tagState.isAddingTag ? addTag() : editTag())"
                  ></Button>
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="!displayedItems.length && !tagState.isAddingTag"
            :class="$style.notFoundRow"
            @click="addNotFoundTask"
          >
            <td colspan="4">Create new tag &quot;{{ search }}&quot; ?</td>
          </tr>
          <tr v-for="tag in displayedItems" :key="tag.id">
            <td :class="$style.nameColumn">
              <div :class="$style.nameText">{{ tag.name }}</div>
            </td>
            <td>
              {{ tag.task_usage }}
              {{ tag.task_usage > 1 || tag.task_usage == 0 ? 'tasks' : 'task' }}
            </td>
            <td>{{ ISODate(tag.created_at, { format: 'LLL dd, yyyy' }) }}</td>
            <td :class="$style.rowAction">
              <DropDown
                :width="160"
                :menu="createActionsMenu(tag)"
                :close-on-select="true"
              >
                <Button
                  size="2"
                  theme="neutral"
                  variant="ghost"
                  icon="dotGrid1x3Horizontal"
                />
              </DropDown>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>
<style module lang="scss">
@import './base.scss';
.searchIcon {
  color: var(--neutral-alpha-9);
}
.textField {
  input {
    @extend .medium-3;
    color: var(--neutral-12);
  }
  padding-left: 0;
  border: none;
  &:hover {
    background-color: transparent;
  }
  &:focus-within {
    border: none;
    box-shadow: none;
  }
}
.divider {
  margin-top: 12px;
}
.searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 256px;
  }
}
.tagCount {
  @extend .medium-2;
}

.tagsTable {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  .notFoundRow {
    cursor: pointer;
  }
  td {
    padding: 12px 0;
  }
  th {
    @extend .medium-2;
    color: var(--neutral-11);
  }

  td {
    @extend .medium-3;
    color: var(--neutral-12);
    border-bottom: 1px solid var(--neutral-10);
    vertical-align: middle;
  }

  .nameColumn {
    width: 236px;
    max-width: 236px;
  }

  .nameText {
    width: 100%;
    max-width: 226px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  tbody tr {
    border-bottom: 1px solid var(--neutral-alpha-4);
  }
  tbody tr td {
    border: none;
  }
  .rowAction {
    display: flex;
    justify-content: right;
  }
  .addTagWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
    }
  }
}
</style>
