import Base from './index.vue'
import Inbox from './Inbox.vue'
import Account from './Account.vue'
import Workspace from './Workspace.vue'
import Team from './Team.vue'
import Notifications from './Notifications.vue'
import Billing from './Billing.vue'
import ComingSoon from '@/pages/ComingSoon.vue'
import Tasks from './Tasks.vue'

const routes = [
  {
    name: 'Settings',
    path: 'settings',
    meta: { title: 'Settings' },
    component: Base,
    redirect: { name: 'AccountSettings' },
    children: [
      {
        name: 'AccountSettings',
        path: 'account',
        component: Account,
        meta: { title: 'Account Settings' },
      },
      {
        name: 'NotificationsSettings',
        path: 'notifications',
        component: Notifications,
        meta: { title: 'Notifications Settings' },
      },

      {
        name: 'TeamSettings',
        path: 'team',
        component: Team,
        meta: { title: 'Team Settings' },
      },
      {
        name: 'BillingSettings',
        path: 'billing',
        component: Billing,
        meta: { title: 'Billing Settings' },
      },
      {
        name: 'WorkspaceSettings',
        path: 'workspace',
        component: Workspace,
        meta: { title: 'Workspace Settings' },
      },

      {
        name: 'InboxSettings',
        path: 'inbox',
        component: Inbox,
        meta: { title: 'Inbox Settings' },
      },
      {
        name: 'CRMSettings',
        path: 'crm',
        component: ComingSoon,
        meta: { title: 'CRM Settings' },
      },
      {
        name: 'TaskSettings',
        path: 'tasks',
        component: Tasks,
        meta: { title: 'Tasks Settings' },
      },
    ],
  },
]

export default routes
